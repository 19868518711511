/** @format */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@400;500;600;700;800&display=swap");

// *  <!-- --------***********************---------- -->
// *  <!--                 themes                   -->
// *  <!-- -------***********************---------- -->

.theme-dark {
  --background: #181a20;
  --textColor: #eaecef;
  --textWhite: #fff;
  --cardBg: #1e2329;
  --heroBtn: #000000;
  --heroBtnColor: rgba(255, 255, 255, 0.98);
  --workBG: #1c1e24;
  --workCard: #e6c74f44;
}
.theme-light {
  --background: #fff;
  --textColor: #000;
  --textWhite: #000;
  --cardBg: #eaecef;
  --heroBtn: whitesmoke;
  --heroBtnColor: rgba(0, 0, 0, 0.98);
  --workBG: whitesmoke;
  --workCard: #ffffff44;
}
::-webkit-scrollbar-track {
  background: #1e1f26 !important;
}
::-webkit-scrollbar-thumb {
  background: #fcd535 !important;
}
::-webkit-scrollbar {
  width: 5px;
}
*body {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  background: var(--background) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed;
}
::selection {
  background: #fcd535;
  color: #000;
}
a {
  text-decoration: none !important;
}
// Theme Start
// Font Family
$Roboto: "Roboto", sans-serif;
$Fjalla: "Fjalla One", sans-serif;
$OpenSans: "Open Sans", sans-serif;
$Montserrat: "Montserrat", sans-serif;

// Colors

// Theme End

// Hero Section Start
.hero_sec {
  padding: 20px 0;
  h1 {
    font-size: 30px;
    font-family: $Roboto;
    font-weight: 500;
    color: var(--textColor);
    text-align: center;
    span {
      font-size: 35px;
      font-weight: 600;
      color: #000;
      background: #fcd535;
    }
  }
  .top_img {
    display: flex;
    justify-content: center;
    img {
      width: 12%;
    }
  }
  .video_main {
    iframe {
      width: 100%;
      border: 1px solid #fcd535;
      height: 400px;
    }
    h3 {
      font-size: 30px;
      font-family: $Roboto;
      color: var(--textColor);

      font-weight: 400;

      span {
        font-size: 40px;
        color: #fcd535;

        font-weight: 600;
      }
    }
    h4 {
      font-family: $Fjalla;
      font-size: 22px;
      color: var(--textColor);

      margin: 15px 0;
    }
    p {
      font-size: 18px;
      font-family: $OpenSans;
      text-align: center;
      color: var(--textColor);
    }
  }

  .card {
    padding: 20px;
    text-align: center;
    border: none;
    border-radius: 20px;
    color: var(--textColor);
    background: var(--cardBg) !important;
    box-shadow: 5px 1px 35px 12px rgba(0, 0, 0, 0.2);
    h3 {
      font-size: 30px;
      font-family: $Roboto;
      font-weight: 400;

      span {
        font-size: 40px;
        color: #fcd535;
        font-weight: 600;
      }
    }
    h4 {
      font-family: $Fjalla;
      font-size: 22px;
      color: var(--textColor);

      margin: 15px 0;
    }
    p {
      font-size: 18px;
      font-family: $OpenSans;
      text-align: center;
    }
  }
  .hero_btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    div {
      color: var(--heroBtnColor);
      border-radius: 20px;
      text-align: center;
      background: var(--heroBtn);
      font-size: 26px;
      width: 55%;
      padding: 14px;
    }
    .top_text {
      margin-bottom: 0% !important;
      font-size: 26px;
      font-family: $Montserrat;
      font-weight: 500;
      text-align: center;
    }
    span {
      font-size: 14px;
      text-align: center;
      font-family: $Montserrat;
      margin-top: 0%;
      color: var(--textWhite);
      font-weight: 300;
    }
  }
}
// Hero Section End

// marketing Section Start
.marketing_sec {
  background-image: url("media/images/market.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 0 !important;
  margin: 50px 0;
  clip-path: polygon(0 0, 100% 0, 100% 94%, 50% 100%, 0 94%);
  .top_text {
    width: 70%;
    h3 {
      font-size: 32px;
      font-family: $Roboto;
      color: var(--textWhite);
      font-weight: 600;
    }
    p {
      margin-top: 25px;
      font-size: 18px;
      font-family: $OpenSans;
      color: var(--textWhite);

      font-weight: 300;
    }
  }
  .right_text {
    h3 {
      font-size: 44px;
      font-family: $Fjalla;
      color: var(--textWhite);

      font-weight: 600;
    }
    p {
      margin-top: 25px;
      font-size: 18px;
      font-family: $OpenSans;
      color: var(--textWhite);

      font-weight: 300;

      span {
        font-weight: 700;
      }
    }
  }
}
// marketing Section End

// Challenge Section Start
.challenge_sec {
  padding: 40px 0;
  .top_text {
    text-align: center;

    p {
      font-size: 20px;
      font-family: $OpenSans;
      font-weight: 700;
      color: var(--textColor);
      margin: 0%;
    }
    h3 {
      margin-top: 10px;
      font-size: 50px;
      font-family: $Fjalla;
      color: var(--textColor);

      span {
        font-weight: 700;
        color: #fcd535;
      }
    }
  }
  .left_text {
    h3 {
      margin: 20px 0;
      font-size: 34px;
      font-family: $Fjalla;
      color: var(--textColor);
      font-weight: 700;
    }
    p {
      font-size: 18px;
      font-family: $OpenSans;
      color: var(--textColor);
      margin-bottom: 30px;
      span {
        font-weight: 700;
      }
    }
    .red_text {
      color: #fcd535;
    }
  }
  .text {
    p {
      font-size: 18px;
      font-family: $OpenSans;
      color: var(--textColor);
      margin-bottom: 30px;
      span {
        font-weight: 700;
      }
    }
  }
  .bottom_text {
    margin: 40px 0;
    h3 {
      font-size: 54px;
      font-family: $Fjalla;
      color: var(--textColor);
      margin: 0%;
      font-weight: 700;
    }
    p {
      font-size: 26px;
      font-family: $Fjalla;
      color: var(--textColor);
      margin: 0%;
    }
  }
}
// Challenge Section End

// Work Section Start
.work_sec {
  background: var(--workBG);
  .top_img {
    img {
      width: 70%;
    }
    h3 {
      font-size: 54px;
      font-family: $Fjalla;
      color: var(--textColor);
      margin: 0%;
      text-align: center;
      font-weight: 700;
    }
  }
  .text {
    width: 80%;
    p {
      font-size: 24px;
      color: var(--textColor);
      font-family: $Fjalla;
      text-align: center;

      span {
        font-weight: 600;
        color: #fcd535;
      }
    }
  }
  .hero_btn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    a {
      color: var(--heroBtnColor);
      background: var(--heroBtn) !important;
      font-size: 26px;
      border-radius: 15px;
      width: 50%;
      padding: 14px;
      border: 0;
    }
    .top_text {
      margin-bottom: 0% !important;
      font-size: 26px;
      font-family: $Montserrat;
      font-weight: 500;
    }
    span {
      font-size: 14px;
      text-align: center;
      font-family: $Montserrat;
      margin-top: 0%;
      color: var(--textWhite);

      font-weight: 300;
    }
  }
}
// Work Section End

// Card Section Start
.card_sec {
  background-image: url("media/images/market.png");
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover;
  padding: 80px 0 100px 0;
  clip-path: polygon(0 5%, 50% 0, 100% 5%, 100% 100%, 0 100%);
  p {
    font-size: 30px;
    font-family: $Fjalla;
    color: var(--textWhite);

    text-align: center;
    margin: 0%;
  }
  h3 {
    text-align: center;
    color: var(--textWhite);

    font-size: 42px;
    font-family: $Fjalla;
    font-weight: 700;
  }
  h4 {
    text-align: center;
    color: var(--textWhite);

    font-size: 28px;
    font-family: $Fjalla;
    font-weight: 700;
  }
  .bottom_text {
    font-size: 26px;
    font-family: $Fjalla;
    color: var(--textWhite);

    text-align: center;
    font-weight: 400;
    margin: 70px 0 0 0;
  }
  .card {
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.4) inset;
    background: var(--workCard);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    border: none;

    border-radius: 20px;
    padding: 10px 20px;
    width: 90%;
    .card_img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
      }
    }
    .head {
      h2 {
        font-size: 38px;
        color: var(--textWhite);
        font-family: $Fjalla;
        font-weight: 700;
        text-align: center;
        line-height: 32px;
        margin: 20px 0px;
        span {
          font-size: 30px;
        }
      }
    }
    .para {
      p {
        font-family: $OpenSans !important;
        font-size: 17px;
        font-weight: 300;
        // text-align: justify;
      }
    }
  }
  .text {
    margin: 30px 0 0 20px;
    .botm_txt {
      font-size: 18px;
      text-align: start;
      font-weight: 300;
      font-family: $OpenSans;
      margin: 25px 0;
      span {
        font-weight: 700;
      }
    }
  }
}

// Card Section End
.Referral {
  background: var(--cardBg);
  padding: 60px 0 !important;
  p {
    color: var(--textWhite);
  }
  // margin: 50px 0;
  h5 {
    font-size: 30px;
    font-family: $Fjalla;
    color: var(--textWhite);

    text-align: center;
    margin: 0%;
  }
  h2 {
    text-align: center;
    color: #000;
    font-size: 42px;
    font-family: $Fjalla;
    background: #fcd535;
    max-width: 600px;
    font-weight: 700;
    margin: auto;
  }
  .top_text {
    h3 {
      font-size: 32px;
      font-family: $Roboto;
      color: var(--textWhite);

      font-weight: 600;
    }
    p {
      margin-top: 25px;
      font-size: 18px;
      font-family: $OpenSans;
      color: var(--textWhite);

      font-weight: 300;
    }
    li {
      margin-top: 25px;
      font-size: 18px;
      font-family: $OpenSans;
      color: var(--textWhite);

      font-weight: 300;
    }
  }
  .hero_btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    div {
      color: var(--heroBtnColor);
      border-radius: 20px;

      background: var(--heroBtn);
      font-size: 26px;
      width: 89%;
      padding: 14px;
    }
    .top_text {
      margin-bottom: 0% !important;
      font-size: 26px;
      font-family: $Montserrat;
      font-weight: 500;
    }
    span {
      font-size: 14px;

      font-family: $Montserrat;
      margin-top: 0%;
      color: var(--textWhite);
      font-weight: 300;
    }
  }
}
// Section Start
.btn-style {
  border-radius: 5px;
  background: #fcd535;
  font-weight: 600;
  padding: 10px 30px;
  font-size: 1.25rem;
  border: 0 !important;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3);
    background: #fcd535ca;
  }
}
.section {
  .top_img {
    display: flex;
    justify-content: center;
    margin-top: -14rem;
    img {
      z-index: 1 !important;
      position: relative;
      width: 60%;
    }
  }
  background-image: url("../public/assets/images/airdrop.png");
  background-position: fixed center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0 !important;
  // margin: 50px 0;
  clip-path: polygon(0 0, 100% 0, 100% 94%, 50% 100%, 0 94%);
  h5 {
    font-size: 30px;
    font-family: $Fjalla;
    color: #fff;

    text-align: center;
    margin: 0%;
  }
  h2 {
    text-align: center;
    color: #fff;

    font-size: 42px;
    font-family: $Fjalla;
    font-weight: 700;
  }
  .top_text {
    width: 70%;
    h3 {
      font-size: 32px;
      font-family: $Roboto;
      color: #fff;

      font-weight: 600;
    }
    p {
      margin-top: 25px;
      font-size: 18px;
      font-family: $OpenSans;
      color: #fff;

      font-weight: 300;
    }
  }
  .right_text {
    h3 {
      font-size: 44px;
      font-family: $Fjalla;
      color: #fff;

      font-weight: 600;
    }
    p {
      margin-top: 25px;
      font-size: 18px;
      font-family: $OpenSans;
      color: #fff;

      font-weight: 300;

      span {
        font-weight: 700;
      }
    }
  }
}
// Section End

// Grid Section Start
.grid_sec {
  padding: 60px 0;

  h3 {
    font-size: 46px;
    color: var(--textColor);
    font-family: $Fjalla;
    font-weight: 800;
  }
  .video_main {
    iframe {
      width: 100%;
      height: 300px;
    }
  }
  p {
    font-size: 18px;
    font-family: $Montserrat;
    color: var(--textColor);
    margin: 20px 0 0 0;
  }
  h4 {
    text-align: end;
    font-size: 32px;
    font-family: $OpenSans;
    color: var(--textColor);
  }
}
// Grid Section End

// Game Section Start
.game_sec {
  padding: 80px 0;
  background: var(--textWhite);
  h3 {
    font-size: 40px;
    font-family: $Fjalla;
    color: #000;
    text-align: center;

    span {
      font-size: 50px;
      font-weight: 700;
    }
  }
}
// Game Section End

// guarantee Section
.guarantee_sec {
  background-image: url("media/images/market.png");

  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover;
  padding: 80px 0;

  h3 {
    font-size: 62px;
    font-family: $Fjalla;
    font-weight: 800;
    color: var(--textWhite);

    text-align: center;
  }
  p {
    color: var(--textWhite);

    font-size: 18px;
    font-family: $OpenSans;
    padding: 10px 40px;
    span {
      font-weight: bold;
    }
  }
  .image {
    display: flex;
    justify-content: center;
    img {
      width: 90%;
    }
  }
  .hero_btn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    button {
      color: rgba(0, 0, 0, 0.98);
      background: #fcd535;
      font-size: 26px;
      border-radius: 15px;
      width: 40%;
      padding: 14px;
      border: 0;
    }
    .top_text {
      margin-bottom: 0% !important;
      font-size: 26px;
      font-family: $Montserrat;
      font-weight: 500;
      color: #000000fa;
    }
    span {
      font-size: 14px;
      text-align: center;
      font-family: $Montserrat;
      margin-top: 0%;
      color: #000000fa;
      font-weight: 300;
    }
  }
}
// guarantee Section End

// Footer
.footer {
  background-color: var(--textWhite);

  img {
  }
  p {
    font-size: 14px;
    font-family: $OpenSans;
    color: var(--heroBtn);

    margin: 10px 0;
  }
  span {
    font-size: 14px;
    margin: 10px 0;
    color: var(--heroBtn);
  }
  .link_footer {
    a {
      text-decoration: none;
      font-size: 13px;
      font-weight: bold;
      color: var(--heroBtn);
      margin: 0 10px;
      font-family: $OpenSans;
    }
  }
}
// Footer End

// Responsive
@media screen and (max-width: 768px) {
  p {
    width: 100% !important;
  }
  body {
    background-image: none !important;
  }
  .hero_sec {
    h1 {
      font-size: 25px;
      // text-align: justify;
      span {
        font-size: 30px;
        font-weight: 600;
        color: #fcd535;
        background: transparent;
      }
    }
    .top_img {
      img {
        width: 40% !important;
      }
    }
    .video {
      iframe {
        height: 160px;
      }
    }
    .card {
      padding: 10px 6px;
      text-align: center;

      h3 {
        font-size: 20px;
        text-align: center;

        span {
          font-size: 25px;
          text-align: center;
        }
      }
      h4 {
        font-size: 18px;
        text-align: center !important;
      }
      p {
        font-size: 17px;
        text-align: center;
      }
    }
    .hero_btn {
      line-height: 15px;
      .icon {
        display: none;
      }
      div {
        width: 100%;
      }
      .top_text {
        font-size: 12px;
        font-weight: 700;
      }
      span {
        font-size: 9px;
      }
    }
  }
  .marketing_sec {
    padding: 10px 0 !important;

    clip-path: none;
    .top_text {
      margin-top: 50px;
      width: 100% !important;
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .right_text {
      h3 {
        font-size: 30px !important;
      }
      p {
        font-size: 15px;
      }
    }
  }
  .challenge_sec {
    padding: 30px 0;
    .top_text {
      p {
        font-size: 16px;
      }
      h3 {
        font-size: 25px;
      }
    }
    .left_text {
      h3 {
        font-size: 28px;
      }
    }
    .bottom_text {
      h3 {
        font-size: 34px;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .work_sec {
    .top_img {
      img {
        width: 90%;
      }
    }
    .text {
      width: 100%;
      p {
        font-size: 18px;
      }
    }

    .hero_btn {
      a {
        font-size: 16px;
        width: 100%;
        padding: 14px;
      }
      .top_text {
        font-size: 20px;
      }
    }
  }
  .card_sec {
    clip-path: none;

    p {
      font-size: 25px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 18px;
    }
    .bottom_text {
      margin-top: 20px;
      font-size: 16px;
    }
    .card {
      width: 100%;
      margin: 15px 0;
    }
    .para {
      p {
        font-size: 14px !important;
      }
    }
    .text {
      margin-left: 0%;
      .botm_txt {
        font-size: 16px;
      }
    }
  }
  .section {
    p {
      font-size: 14px;
      // text-align: justify;
      width: 100% !important;
    }
    .top_img {
      img {
        width: 95%;
      }
    }
    .top_text {
      width: 100%;

      p {
        font-size: 14px;
        // text-align: justify;
        width: 100% !important;
      }
    }
  }
  .grid_sec {
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 14px;
      text-align: center;
      width: 100% !important;
    }
    h4 {
      font-size: 25px;
    }
    .video_main {
      iframe {
        height: 160px;
      }
    }
  }
  .game_sec {
    h3 {
      font-size: 24px;
      span {
        font-size: 34px;
      }
    }
  }
  .guarantee_sec {
    h3 {
      font-size: 30px;
    }
    p {
      padding: 10px;
      font-size: 16px;
    }
    .hero_btn {
      line-height: 15px;
      .icon {
        display: none;
      }
      button {
        width: 100%;
      }
      .top_text {
        font-size: 12px;
        font-weight: 700;
      }
      span {
        font-size: 9px;
      }
    }
  }
  .footer {
    img {
      width: 35%;
    }
  }
}
